<template>
  <v-main>
    <v-container>
      <v-row>
        <v-col>
          <h1
            class="
              text-lg-h4 text-h6 text-color-title text-center
              font-weight-bold
            "
          >
            Introduction
          </h1>
          <p class="text-center px-lg-10">
            'VBRT' stands for Virtual Brand Representative Training that aims to
            provide the updated product information, function and sales
            technique in order to support your sale and marketing team to expand
            La-Z-Boy products to the market.
          </p>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6">
          <video
            src="https://lazboyasiaconf.com/videos/intro.mp4"
            :poster="require('@/assets/training/three-man.png')"
            controls
          />
        </v-col>
        <v-col lg="6" class="align-self-end">
          <h1 class="text-lg-h4 text-h6 text-color-title font-weight-bold">
            Get to know La-Z-Boy
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <video
            src="https://lazboyasiaconf.com/videos/ep1.mp4"
            :poster="require('@/assets/training/ep1.jpg')"
            controls
          />
          <h1 class="text-lg-h4 text-h6 text-color-title font-weight-bold">
            Reclinea Rocker Recliner
          </h1>
        </v-col>
        <v-col md="6" class="align-self-end">
          <video
            src="https://lazboyasiaconf.com/videos/ep2.mp4"
            :poster="require('@/assets/training/ep2.jpg')"
            controls
          />
          <h1 class="text-lg-h4 text-h6 text-color-title font-weight-bold">
            Reclinea Wall Away Recliner
          </h1>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" md="6">
          <video
            src="https://lazboyasiaconf.com/videos/ep3.mp4"
            :poster="require('@/assets/training/ep3.jpg')"
            controls
          />
          <h1 class="text-lg-h4 text-h6 text-color-title font-weight-bold">
            Reclinea Glider Swivel Recliner
          </h1>
        </v-col>
        <v-col lg="6" class="align-self-end">
          <video
            src="https://lazboyasiaconf.com/videos/ep4.mp4"
            :poster="require('@/assets/training/ep4.jpg')"
            controls
          />
          <h1 class="text-lg-h4 text-h6 text-color-title font-weight-bold">
            Lift Chairs
          </h1>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>
<script>
export default {
  layout: {
    name: 'home',
    props: {
      activePage: 'training'
    }
  },
  data() {
    return {
      dialog: false,
    };
  },
  methods: {
    submit() {
      this.dialog = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.question-box {
  background-color: rgb(21 101 192 / 62%);
  border-radius: 20px;
  color: white;
}

.v-text-field__details {
  display: none;
}

.text-lg-h4,
.text-h6 {
  font-style: italic;
}
.textarea {
  background: white;
  border-radius: 4px;
  width: 100%;
  padding: 10px;
  height: 80%;
  color: #666666;
}
.textarea::placeholder {
  color: #6da6b5;
}
</style>
